/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { useState } from 'react';
import { useEnvDetails } from '../../hooks/fetchEnvDetails';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Drawer, Button, Typography, IconButton } from '@material-ui/core';

import Close from '@material-ui/icons/Close';
import { CodeSnippet, InfoCard } from '@backstage/core-components';

export default {
    title: 'Layout/Drawer',
    component: Drawer,
};

const useDrawerStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            width: '50%',
            justifyContent: 'space-between',
            padding: theme.spacing(2.5),
        },
    }),
);

const useDrawerContentStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        icon: {
            fontSize: 20,
        },
        content: {
            height: '80%',
            backgroundColor: '#EEEEEE',
        },
        secondaryAction: {
            marginLeft: theme.spacing(2.5),
        },
    }),
);

/* Example content wrapped inside the Drawer component */
const DrawerContent = ({
    toggleDrawer,
    environment,
}: {
    toggleDrawer: (isOpen: boolean) => void;
    environment: any;
}) => {
    const classes = useDrawerContentStyles();
    const text = String(useEnvDetails(environment.space, environment.id));

    return (
        <>
            <div className={classes.header}>
                <Typography variant="h6">{environment.name}</Typography>
                <IconButton
                    key="dismiss"
                    title="Close the drawer"
                    onClick={() => {
                        toggleDrawer(false);
                    }}
                    color="inherit"
                >
                    <Close className={classes.icon} />
                </IconButton>
            </div>

            <div style={{ marginTop: '10px' }}>
                <InfoCard title="Env Info" >
                    <CodeSnippet text={environment.info} language="json" showLineNumbers showCopyCodeButton />
                </InfoCard>
            </div>
            <div style={{ marginTop: '10px' }}>
                <InfoCard title="Outputs" >
                    <CodeSnippet text={text} language="json" showLineNumbers showCopyCodeButton />
                </InfoCard>
            </div>
        </>
    );
};

/* Default drawer can toggle open or closed.
 * It can be cancelled by clicking the overlay
 * or pressing the esc key.
 */
export const DefaultDrawer = ({ environment }: {
    environment: any;
}) => {
    const [isOpen, toggleDrawer] = useState(false);
    const classes = useDrawerStyles();

    return (
        <>
            <Button
                variant="contained"
                color="primary"
                onClick={() => toggleDrawer(true)}
            >
                more
            </Button>
            <Drawer
                classes={{
                    paper: classes.paper,
                }}
                anchor="bottom"
                open={isOpen}
                onClose={() => toggleDrawer(false)}
            >
                <DrawerContent toggleDrawer={toggleDrawer} environment={environment} />
            </Drawer>
        </>
    );
};