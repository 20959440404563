import { Entity } from '@backstage/catalog-model';
import { useEntity } from '@backstage/plugin-catalog-react';
import React from 'react';
import { useEnvsInfo } from '../../hooks/fetchEnvs';
// import { useEnvDetails } from '../../hooks/fetchEnvDetails';
// // import { makeStyles } from '@material-ui/core/styles';
import { TorqueAnnotationedEntity } from '../../types';
import { TORQUE_SPACE_ANNOTATION, TORQUE_ENV_ANNOTATION, TORQUE_MISSING_ANNOTATION_ERROR } from '../../annotations';
import { SubvalueCell, Table, TableColumn } from '@backstage/core-components';
import { Chip, Typography } from '@material-ui/core';
import LogoImg from "../../images/logo.svg";

// import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { Link } from 'react-router-dom';
import { DefaultDrawer } from './DrawerComponent';
enum StatusColor {
    default = "default",
    primary = "primary",
    secondary = "secondary",
}

enum StatusVariant {
    default = "default",
    outlined = "outlined",
}

class EnvStatus {
    color = StatusColor.default;
    variant = StatusVariant.default;
    name: string | null = null;
}

class EnvironmentItem {
    id: string | null = null;
    name: string | null = null;
    blueprint_name: string | null = null;
    state = new EnvStatus();
    space: string | null = null
    outputs: string | null = null;
    info: string | null = null
}

type DenseTableProps = {
    environments: EnvironmentItem[];
}

export const isTorqueAvailable = (entity: Entity) =>
    Boolean(entity.metadata.annotations?.[TORQUE_SPACE_ANNOTATION])

export const DanseTable = ({ environments }: DenseTableProps) => {
    // const config = useApi(configApiRef);
    // const classes = useStyles();
    const columns: TableColumn[] = [
        {
            title: 'Link',
            field: 'link',
            width: '50'
        },
        {
            title: 'State',
            field: 'state',
            width: '80',
        },
        {
            title: 'Name',
            // Only needed if you want subvalue searchable
            customFilterAndSearch: (query, row: any) => `${row.name} ${row.subvalue}`.toLocaleUpperCase('en-US').includes(query.toLocaleUpperCase('en-US')),
            field: 'name',
            highlight: true,
            render: (row: any): React.ReactNode => (
                <SubvalueCell value={row.name} subvalue={row.blueprint_name} />
            ),
        },
        {
            title: 'Actions',
            field: 'defaultDrawer',
            width: '100',
        },

    ];

    const data = environments.map(env => {
        var env_url = `torque?environmentId=${env.id}`;
        return {
            link: (
                <Link to={env_url}>
                    <img
                        src={LogoImg}
                        alt="example"
                        width="30"
                    />
                </Link>
            ),
            state: (
                <Chip style={{ marginBottom: '0px' }} color={env.state.color} variant={env.state.variant} label={env.state.name} />
            ),
            name: env.name,
            blueprint_name: env.blueprint_name,
            defaultDrawer: <DefaultDrawer environment={env} />,
        };
    });

    return (
        <Table
            title="Torque Environments"
            options={{ search: true, paging: true, pageSize: 6, padding: 'dense' }}
            columns={columns}
            data={data}
        />
    );
};

export const setStatus = (env_status: string) => {
    let status = new EnvStatus();
    status.name = env_status;
    if (env_status == 'Active') {
        status.color = StatusColor.primary;
        status.variant = StatusVariant.default;
    } else if (env_status == 'Launching') {
        status.color = StatusColor.primary;
        status.variant = StatusVariant.outlined;
    } else if (env_status == 'Ended') {
        status.color = StatusColor.default;
        status.variant = StatusVariant.outlined;
    } else if (env_status == 'Active with Error') {
        status.color = StatusColor.secondary;
        status.variant = StatusVariant.outlined;
    }

    return status;
}

const buildEnvInfo = (env: any) => {
    const info: string[] = [];
    info.push(`Created At: ${env.details.state.execution.start_time}`);
    info.push(`Owner: ${env.owner.email}`);
    info.push(`Blueprint: ${env.details.definition.metadata.blueprint_name}`);
    info.push(`Last Accessed: ${env.details.definition.metadata.last_accessed}`);
    info.push(`Cost: ${env.sum}`);
    return info.join('\n');
}

export const TorqueCardComponent = () => {
    let space: string;
    let environment_name: string;

    try {
        const { entity } = useEntity<TorqueAnnotationedEntity>();
        space = entity.metadata.annotations[TORQUE_SPACE_ANNOTATION];
        environment_name = entity.metadata.annotations[TORQUE_ENV_ANNOTATION];
    } catch (e) {
        space = 'Sample';
        environment_name = '';
    }

    const data = useEnvsInfo(space = space, environment_name = environment_name);
    if (Array.isArray(data)) {
        const envs = data.map(envItem => {
            let env = new EnvironmentItem();
            env.blueprint_name = envItem.details.definition.metadata.blueprint_name;
            env.id = envItem.id;
            env.name = envItem.details.definition.metadata.name;
            env.state = setStatus(envItem.details.computed_status);
            env.info = buildEnvInfo(envItem);
            env.space = space;
            return env;
        })
        return <DanseTable environments={envs || []} />;

    } else if (data instanceof Error && data.message === TORQUE_MISSING_ANNOTATION_ERROR) {
        return <Typography color="error">{data.message}</Typography>
    } else {
        return <Typography>{data.message}</Typography>
    }
}