import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';

import { TORQUE_MISSING_ANNOTATION_ERROR } from '../annotations';
import { useState } from 'react';

export function useEnvDetails(space: string, environment_id: string) {
    const [data, setData] = useState<string>('');
    const discovery = useApi(discoveryApiRef);

    if (!space || !environment_id) {
        console.error('space: ' + space + ' environment_id: ' + environment_id);
        return new Error(TORQUE_MISSING_ANNOTATION_ERROR);
    }

    if (data) {
        console.log(`cached data: ${data}`);
        return data;
    }

    let source: EventSource;
    createUrl().then((url) => {
        source = new EventSource(url);
        source.addEventListener('update-success', (message) => {
            try {
                if (message instanceof MessageEvent) {
                    const details = JSON.parse(message.data);
                    const keyValueString = Object.entries(details.details.state.outputs)
                        .map(([_, value]: [string, any]) => `${value.name}: ${value.value}`)
                        .join('\n');
                    console.log(`outputs: ${keyValueString}`);
                    setData(keyValueString);
                }
            } catch (e) {
                console.error(e);
            }
        });

        source.addEventListener('update-failure', (message) => {
            if (message instanceof MessageEvent) {
                setData(message.data);
            }
        });
    });

    return data;

    async function createUrl() {
        let route = 'get_environment_details';
        let params = new URLSearchParams({ space, environment_id });
        const url = `${await discovery.getBaseUrl('torque')}/${route}`;
        return `${url}?${params}`;
    }
}